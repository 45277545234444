import {
    ChartPieIcon,
    TemplateIcon,
    CursorClickIcon,
    StatusOfflineIcon,
    ServerIcon,
    DatabaseIcon,
  } from '@heroicons/react/outline'
  
  const features = [
    {
      name: 'Drag & Drop Form Builder',
      description: 'Create customisable forms with a simple interface. Benefit from menus, detail screens, and listing with our drag & drop form builder.',
      icon: CursorClickIcon,
    },
    {
      name: 'Workflow Integration',
      description: 'Send and receive data between your systems with our workflow integration. Integrate with your existing workflow systems or create your own.',
      icon: DatabaseIcon,
    },
    {
      name: 'Work Offline',
      description: 'Access all of the latest forms within your system from our mobile app even when out in the field without a connection.',
      icon: StatusOfflineIcon,
    },
    {
      name: 'Customised Templates',
      description: 'Create customised templates for your forms to export and send paperwork in the same format as you use currently.',
      icon: TemplateIcon,
    },
    {
      name: 'Analytics Dashboards',
      description: 'Keep track of all form entries and create beautiful real-time data reports. This is an add-on feature.',
      icon: ChartPieIcon,
    },
    {
      name: 'Data Distribution',
      description: 'Get form submissions into the hands of the people who need it most via our data connectors.',
      icon: ServerIcon,
    },
  ]
  
  export default function Features() {
    return (
      <div className="relative bg-white pb-16 sm:pb-24 lg:pb-32">
        <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <h2 className="text-base font-semibold uppercase tracking-wider text-blue-600">Features</h2>
          <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Everything you need to digitise your paperwork
          </p>
          <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
            Create solutions that work for your business. No code required.
          </p>
          <div className="mt-12">
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {features.map((feature) => (
                <div key={feature.name} className="pt-6">
                  <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center rounded-md bg-blue-500 p-3 shadow-lg">
                          <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">{feature.name}</h3>
                      <p className="mt-5 text-base text-gray-500">{feature.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }