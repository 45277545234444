import React from "react";
import { Link } from "react-router-dom";

export default function Gdpr() {
  return (
    <div className="relative px-4 sm:px-6 lg:px-8">
      <div className="text-lg max-w-prose mx-auto">
        <h1>
          <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            GDPR
          </span>
        </h1>
      </div>
      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <p>
          Information about our privacy &amp; General Data Protection Regulation
          efforts
        </p>
        <p>
          The European General Data Protection Regulation (“GDPR”) legislation
          introduces a new set of rules for the processing of personal data.
          <br />
          GDPR is the most modern and fully integrated legislation on data
          privacy, and the applicability of the GDPR does not stop at the
          borders of the European Economic Area (“EEA”).
        </p>
        <p>
          Swiftforms has embraced&nbsp;the requirements of GDPR, and we consider
          GDPR to be the benchmark for our privacy and data protection efforts.
          <br />
          Below&nbsp;you’ll find information about GDPR, as well as&nbsp;answers
          about data protection and privacy at Swiftforms, for your convenience.
        </p>
        <p>
          You should also consult our&nbsp;Privacy Policy&nbsp;and&nbsp;
          <Link to="/terms-and-conditions">Terms of Use</Link>
          &nbsp;for&nbsp;further details on these topics.
        </p>
        <p>
          <b>Common questions about GDPR and privacy at Swiftforms</b>
        </p>
        <p>What is GDPR?</p>
        <p>
          “GDPR” or “General Data Protection Regulation “(EU Regulation 2016/679
          of the European Parliament and of the Council of 27 April 2016 on the
          protection of natural persons with regard to the processing of
          personal data and on the free movement of such data, and repealing
          Directive 95/46/EC) is the new European privacy legislation.
        </p>
        <p>
          It aims to harmonise legislation throughout the EU with the intention
          to:
        </p>
        <ul>
          <li>increase the general awareness of data privacy,</li>
          <li>
            allow individuals to take control over their privacy and their
            fundamental rights, and,
          </li>
          <li>
            strengthen security requirements throughout companies and
            organisations.
          </li>
        </ul>
        <p>Where/when does GDPR apply?</p>
        <p>
          GDPR went into effect on 25 May 2018 and applies to:
          <br />
          – all organisations established in the European Economic Area (“EEA”)
          <br />– to organisations, whether or not established in the EEA, that
          process personal data in connection with either the offering of goods
          or services to natural persons in the EEA or the monitoring of
          behaviour that takes place within the EEA.
        </p>
        <p>
          Thus from the moment there is processing of personal data in the EEA,
          or from the moment a person located in the EEA is referenced, GDPR
          will apply (regardless of whether the processing entity is located in
          the EEA).
        </p>
        <h2>What does the Swiftforms Platform do?</h2>
        <p>
          Swiftforms provides a solution for rapidly creating data-driven
          business apps on mobile and desktop devices, all with no programming
          required.
        </p>
        <p>
          This enables businesses to reduce paper, enhance productivity and
          improve accuracy in a wide variety of industries and field usage
          scenarios.
        </p>
        <p>
          Swiftforms platform provides an “end to end” platform for creating
          custom business apps, securely capturing and accessing data through
          these apps, safe cloud-based storage of data, and
          connection/integration of data with other external services.
        </p>
        <p>
          Swiftforms is offered as “Software as a Service” (“SaaS”), which is a
          licensing and delivery model where software is centrally hosted and
          made available to multiple customers over a network, including through
          interacting applications (including mobile/desktop apps, web browser,
          and/or connectors to third-party systems).
        </p>
        <p>
          Personal Data (as defined by GDPR) is only processed by Swiftforms
          under the control and direction of Swiftforms customers.
        </p>
        <h2>Who is a “Controller” or a “Processor” under GDPR?</h2>
        <p>
          Swiftforms customers&nbsp;decide&nbsp;the nature of data being
          captured and stored, and they choose which
          individuals&nbsp;interact&nbsp;with the&nbsp;Swiftforms Platform (thus
          in turn whose personal data is captured and processed).
          <br />
          It is thus you, as a Swiftforms customer, that legally acts as the
          “Controller” as defined under GDPR.
        </p>
        <p>
          Swiftforms provides the means (the Swiftforms Platform) for Swiftforms
          customers to capture data and interact with their respective users,
          clients and other parties.
          <br />
          As such, Swiftforms is only processing personal data for, and on
          behalf of, Swiftforms customers as a “Processor”, as defined under
          GDPR.
        </p>
        <p>
          The only case where Swiftforms acts as a Controller is&nbsp;during a
          limited set of direct interactions with Swiftforms customers (these
          being governed by the Swiftforms Privacy Policy).
        </p>
        <p>What is Swiftforms doing to meet GDPR requirements?</p>
        <p>
          Swiftforms has undertaken a number of initiatives to meet GDPR
          requirements:
        </p>
        <p>
          <b>Hosting of EEA customers exclusively within EU data centres</b>
          <br />
          All data for customers identified as being located in the EEA is
          hosted within our cloud partner’s (Microsoft Azure) West Europe
          (Amsterdam) and North Europe (Dublin) data centres.
        </p>
        <p>
          <b>Encryption of data at rest and in transit</b>
          <br />
          All data stored within the Swiftforms Platform is encrypted on our
          servers, be this within a database, storage service or file backups.
          <br />
          All data transport between servers, services and/or devices (both
          internally and externally) occur exclusively over SSL&nbsp;encrypted
          transport protocols.
        </p>
        <p>
          <b>Dedicated GDPR and Privacy Information page</b>
          <br />
          We have created a dedicated webpage with detailed information about
          Swiftforms’ privacy efforts at https://www.Swiftforms.co.uk/gdpr.
        </p>
        <p>
          <b>Data Protection Officer (DPO)</b>
          <br />
          The Swiftforms DPO supervises our entire data privacy program and
          works in close conjunction with Swiftforms team members on matters
          relating to security, data protection and privacy.
        </p>
        <p>
          <b>
            Data Processing Addendum (DPA)
            <br />
          </b>
          On request, Swiftforms can{" "}
          <a href="http://www.appenate.com/dpa/">
            <span class="s2">provide a standard DPA</span>
          </a>{" "}
          which is a self-serve and easy-to-execute document pre-signed by a
          Swiftforms director.&nbsp; The DPA clearly&nbsp;outlines the data
          processing&nbsp;terms between Swiftforms and a customer, and it only
          requires a Swiftforms customer’s electronic signature to complete.
          <br />
          This allows Swiftforms’ European customers to provide the signed DPA
          to auditors&nbsp;demonstrating that the Swiftforms Platform is used to
          process data in a way that meets&nbsp;their GDPR compliance
          obligation.
        </p>
        <p>
          <b>
            “Is Personal Data” flags for data entities in the platform (e.g.
            forms and data&nbsp;sources)
          </b>
          <br />
          The Swiftforms Platform now provides new checkbox options to allow
          Swiftforms customers to flag/identify data fields that contain
          personal data.
          <br />
          This, in turn, allows the Swiftforms Platform to anonymise these
          fields when data leaves the Swiftforms Platform (e.g. via manual
          export, connector integrations, and/or the Swiftforms Platform API).
        </p>
        <p>
          <b>Careful vetting of sub-processors</b>
          <br />
          Each sub-processor of Swiftforms is vetted by our team in the areas of
          security, contractual terms, data processing agreements, and EU
          standard contractual clauses / Privacy Shield.
        </p>
        <p>
          <b>Up-to-date contractual documents/privacy policies</b>
          <br />
          Our contractual documents have been updated to contain necessary GDPR
          provisions.
        </p>
        <p>
          <b>Product Development</b>
          <br />
          All new Swiftforms Platform functionality that is introduced from May
          2018 onwards will include consideration of the following:
        </p>
        <ul>
          <li>
            the GDPR principles of “privacy by design” and “privacy by default”,
          </li>
          <li>
            giving flexibility to all customers while remaining within GDPR
            guidelines
          </li>
          <li>keeping all changes as simple as possible</li>
        </ul>
        <p>
          What is a Data Processing Addendum (DPA) and does Swiftforms provide
          this?
        </p>
        <p>
          If Swiftforms’ processing of personal data for your organisation falls
          within the material and/or territorial scope of GDPR (articles 2 &amp;
          3), the legislation (GDPR article 28) requires that this processing
          occurs under a Data Processing Addendum (DPA).
        </p>
        <p>
          The Swiftforms DPA incorporates with our&nbsp;Terms of Use&nbsp;so, by
          having acknowledged our Terms of Use and continuing to use Swiftforms,
          you’re already accepting our DPA. You can&nbsp;reference our DPA if
          you need to show auditors that your use of Swiftforms meets your GDPR
          obligations in terms of the data that we process on your behalf.
        </p>
        <p>
          If your organisation requires that you execute an explicitly signed
          data processing agreement with Swiftforms, then send an email to{" "}
          <a href="mailto:support@swiftforms.co.uk">
            <span class="s4">support@swiftforms.co.uk</span>
          </a>{" "}
          to request one.
          <br />
          We’ll promptly counter-sign and return a digital copy to you for your
          records.
        </p>
        <p>What types of Personal Data does the Swiftforms Platform process?</p>
        <p>
          For registered users on the platform, basic contact information is
          processed (i.e. direct identifiable personal data such as e-mail
          addresses or name) as well as minimal device information, connection
          information and geolocation.
        </p>
        <p>
          Other personal information may also be processed by the Swiftforms
          Platform through data captured and stored by Swiftforms customers.
          <br />
          While it’s not up to us to control what data we receive, this can
          include items such as contact information, IP addresses, and other
          data.
          <br />
          We process customer-submitted data as part of our contractual
          obligation to our customers, and in accordance with applicable laws,
          including the GDPR.
        </p>
        <h2>
          Does the Swiftforms Platform utilise sub-processors? Show me the list?
        </h2>
        <p>
          We use certain sub-processors to assist in providing the Swiftforms
          platform to customers. A sub-processor is a third-party data processor
          engaged by Swiftforms, that has or potentially will have access to or
          process customer data (which may&nbsp;include personal data).
        </p>
        <p>How long does personal data remain on the Swiftforms Platform?</p>
        <p>
          <b>Swiftforms production (live) environments</b>
        </p>
        <ul>
          <li>
            Registered users
            <br />
            All personal data relating to a user is either deleted or anonymised
            within 7 days of the user deletion action. The 7 day period allows
            for fast recovery if the deletion was accidental.
            <br />
            For the avoidance of doubt, deactivation of a user account does not
            remove the account or its personal data; the account is simply
            archived.
          </li>
          <li>
            All other data entities
            <br />
            This is determined and configured by Swiftforms’ customers, based on
            their own agreements with data subjects in turn.
            <br />
            The Swiftforms Platform provides customers with&nbsp;functionality
            to delete data entities as needed.
          </li>
        </ul>
        <p>
          <b>Swiftforms backups</b>
        </p>
        <p>
          Backups are performed on a regular basis and are kept in encrypted,
          secure storage for up to 60 days.
          <br />
          This means that items deleted in production environments are available
          for restoration from backups for up to 60 days thereafter.
        </p>
        <p>
          <b>Swiftforms test/development environments</b>
        </p>
        <p>
          Data is occasionally extracted from production to development/testing
          environments for support, testing and debugging purposes.
          <br />
          When this occurs, personal data is anonymised in order to assure
          privacy.
        </p>
        <h2>
          Who has access to personal data stored on the Swiftforms Platform?
        </h2>
        <p>
          Personal data stored on the Swiftforms Platform may be visible to:
        </p>
        <ul>
          <li>
            Swiftforms customers
            <br />
            Depending on their assigned access permissions, users can view and
            access personal data collected and/or stored within their Swiftforms
            customer account.
          </li>
          <li>
            Swiftforms employees &amp; contractors
            <br />
            All employees &amp; contractors are trained and contractually
            committed to following Swiftforms’ privacy, security and data
            protection practices.
          </li>
          <li>
            Sub-processors
            <br />
            We work with carefully selected services to provide aspects of the
            Swiftforms platform and may&nbsp;process data with these
            services&nbsp;as necessary to provide Swiftforms platform services.
          </li>
          <li>
            Other third parties if required by applicable law or where
            Swiftforms has a good-faith belief that such disclosure is
            reasonably necessary to:
            <br />
            (a) protect the safety of any person from death or serious bodily
            injury, or
            <br />
            (b) prevent fraud or abuse
          </li>
        </ul>
        <p>
          Access only occurs to the extent and limited to, such personal data as
          necessary for that specific purpose of the respective party.
        </p>
        <h2>
          Where is personal data stored? Does it leave the European Economic
          Area?
        </h2>
        <p>
          The Swiftforms Platform is hosted in 3 regions (“nodes”) across the
          world – specifically USA, Europe and Australia.
        </p>
        <p>
          All customers that identify themselves as being located within Europe
          are hosted exclusively within our West Europe (Amsterdam &amp; Dublin)
          datacenters.
          <br />
          As such, all data captured and/or stored on the Swiftforms Platform
          for European customers will remain within the EEA by default.
        </p>
        <p>
          Swiftforms only exports personal data outside of the European Economic
          Area (“EEA”) if and when required by:
        </p>
        <ul>
          <li>
            a respective sub-processor for the correct functioning of the
            service they offer (e.g. push notifications),
          </li>
          <li>
            other recipients, only to the extent required to support the correct
            and/or compliant functioning of the Swiftforms Platform
          </li>
        </ul>
        <p>
          Where data export occurs, Swiftforms ensures that such export occurs
          under the adequacy decisions as allowed by GDPR (EU-US Privacy Shield,
          binding corporate rules, applicable EU standard contractual clauses,
          such other methods as allowed per the GDPR), and keeps the exported
          data to a minimum as necessary.
        </p>
        <p>
          Swiftforms also provides software features to Swiftforms
          customers&nbsp;which allows them to anonymise personal data upon
          export out of the Swiftforms Platform.
        </p>
        <h2>
          Is data processed by Swiftforms used for direct marketing or automated
          decision making?
        </h2>
        <p>
          Registered administrator users may be contacted by Swiftforms with
          news or offers about the Swiftforms Platform.
          <br />
          This communication can be unsubscribed at any time by the user.
        </p>
        <p>
          Swiftforms does not use personal data processed through the Swiftforms
          Platform for direct marketing purposes, nor does the Swiftforms
          Platform employ automated decision-making processes/techniques which
          create or deny rights to individual persons.
          <br />
          We only process personal data under instruction and under control of
          the Swiftforms customer for the purpose of the Swiftforms Platform
          solution.
        </p>
      </div>
    </div>
  );
}
