import dropbox from '../images/connectors/dropbox.webp'
import azure from '../images/connectors/azure.webp'
import box from '../images/connectors/box.png'
import googledrive from '../images/connectors/googledrive.webp'
import onedrive from '../images/connectors/onedrive.webp'
import sharepoint from '../images/connectors/sharepoint.webp'

export default function Connectors() {
    return (
      <div className="bg-white">
        <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <p className="text-center text-lg font-semibold text-gray-600">
           Push and pull data between your apps and other systems with our built-in connectors. 
          </p>
          <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
            <div className="col-span-1 flex justify-center bg-gray-50 py-8 px-8">
              <img
                className="max-h-12"
                src={dropbox}
                alt="Dropbox"
              />
            </div>
            <div className="col-span-1 flex justify-center bg-gray-50 py-8 px-8">
              <img className="max-h-12" src={azure} alt="Azure" />
            </div>
            <div className="col-span-1 flex justify-center bg-gray-50 py-8 px-8">
              <img className="max-h-12" src={onedrive} alt="Onedrive" />
            </div>
            <div className="col-span-1 flex justify-center bg-gray-50 py-8 px-8">
              <img className="max-h-12" src={googledrive} alt="Google Drive" />
            </div>
            <div className="col-span-1 flex justify-center bg-gray-50 py-8 px-8">
              <img
                className="max-h-12"
                src={sharepoint}
                alt="Sharepoint"
              />
            </div>
            <div className="col-span-1 flex justify-center bg-gray-50 py-8 px-8">
              <img
                className="max-h-12"
                src={box}
                alt="Box"
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
  