import impactLogo from "../images/impact-logo.gif"
import qtsLogo from "../images/qts-logo.png"
import aaaLogo from "../images/AAA-logo.png"

export default function Example() {
    return (
      <div className="bg-blue-200 bg-opacity-25">
        <div className="mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="lg:grid lg:grid-cols-2 lg:gap-8">
            <h2 className="mx-auto max-w-md text-center text-3xl font-bold tracking-tight text-blue-900 lg:max-w-xl lg:text-left">
              Used by the UK's most forward-thinking companies.
            </h2>
            <div className="mt-8 flow-root self-center lg:mt-0">
              <div className="-mt-4 -ml-8 flex flex-wrap justify-between lg:-ml-4">
                <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow justify-center lg:ml-4 lg:flex-grow-0">
                  <img className="h-12" src={qtsLogo} alt="Tuple" />
                </div><div className="mt-4 ml-8 flex flex-shrink-0 flex-grow justify-center lg:ml-4 lg:flex-grow-0">
                  <img
                    className="h-12"
                    src={impactLogo}
                    alt="Workcation"
                  />
                </div>
                <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow justify-center lg:ml-4 lg:flex-grow-0">
                  <img className="h-12" src={aaaLogo} alt="Level" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  