import { Disclosure } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import swiftformsSmall from "../images/swift_s.png"
import swiftformsLarge from "../images/swiftforms_logo_horizontal_blue.png"


const navigation = [
    {
        label: 'Home',
        href: '/',
    },
    {
        label: 'Features',
        href: '/features',
    },
    {
        label: 'Pricing',
        href: '/pricing',
    },
    {
        label: 'Analytics',
        href: '/analytics',
    },
    {
      label: 'Contact',
      href: '/contact'
    },
    {
        label: 'About',
        href: '/about',
    },
]

export default function Nav() {
  return (
    <Disclosure as="nav" className="bg-white shadow">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="flex-shrink-0 flex items-center">
                  <img
                    className="block lg:hidden h-8 w-auto"
                    src={swiftformsSmall}
                    alt="Swiftforms"
                  />
                  <img
                    className="hidden lg:block h-8 w-auto"
                    src={swiftformsLarge}
                    alt="Swiftforms"
                  />
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                  {/* Current: "border-blue-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                  {navigation.map((item) => {
                        return (<a
                        href={item.href}
                        key={item.href}
                        className="font-medium text-gray-500 hover:text-gray-900 inline-flex items-center px-1 pt-1 text-sm"
                        >
                            {item.label}
                        </a>)
                  }
                  )}
                </div>
              </div>

              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <a href="https://secure.swiftforms.co.uk/Account/LogOn">
                  <button
                    type="button"
                    className="relative inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    <span>Sign In</span>
                  </button>
                  </a>
                  <a href="https://analytics.swiftforms.co.uk/auth/login">
                  <button
                    type="button"
                    className="relative inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 ml-3 text-sm font-medium text-white shadow-sm hover:bg-gray-800  focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                  >
                    <span>Analytics Sign In</span>
                  </button>
                  </a>
                </div>
              </div>

              <div className="-mr-2 flex items-center sm:hidden"> {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="pt-2 pb-3 space-y-1">
              {/* Current: "bg-blue-50 border-blue-500 text-blue-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
              {navigation.map((item) => {
                    return (
                        <Disclosure.Button
                        as="a"
                        href={item.href}
                        className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                        >
                            {item.label}
                        </Disclosure.Button>
                    )}
                )}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
