import React from "react";
import { Link } from "react-router-dom";

export default function DataProcessing() {
  return (
    <div className="relative px-4 sm:px-6 lg:px-8">
      <div className="text-lg max-w-prose mx-auto">
        <h1>
          <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Data Processing Addendum
          </span>
        </h1>
      </div>
      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <p>
          <b>
            <i>Version 1.0 (May 2018)</i>
          </b>
        </p>
        <p>
          As referenced in our&nbsp;
          <Link to="/terms-and-conditions">Terms of Use</Link>&nbsp;(“
          <b>Main Agreement</b>“), this Data Processing Addendum (“<b>DPA</b>”)
          will only apply where Swiftforms processes personal data pertaining to
          European Union and United Kingdom persons as part of Swiftforms
          products, services and websites (collectively the “<b>Service</b>”) on
          behalf of a Swiftforms customer (the “<b>Customer</b>“). The
          capitalised terms used in this DPA but not defined herein shall have
          the same meaning as defined in the Main Agreement. In the event of a
          conflict between this DPA and the Main Agreement, this DPA shall
          prevail. Each of the Customer and Swiftforms may be referred to herein
          as a <b>“party” </b>and together as the “<b>parties</b>”.
        </p>
        <p>
          The parties have agreed to enter into this DPA to ensure that adequate
          safeguards are put in place with respect to the protection of such
          Personal Data as required by EU Data Protection Laws.
        </p>
        <p>
          If the Customer entity is not a party to the Main Agreement directly
          with Swiftforms but is instead a customer indirectly via an authorised
          vendor of Swiftforms services, this DPA is not valid and is not
          legally binding. Such entity should contact the authorised vendor to
          discuss whether any amendment to its agreement with that vendor may be
          required.
        </p>
        <p>
          <b>DATA PROCESSING TERMS</b>
        </p>
        <p>
          In the course of providing the Service to the Customer pursuant to the
          Main Agreement, Swiftforms may Process Personal Data on behalf of the
          Customer. Swiftforms agrees to comply with the following provisions
          with respect to any Personal Data submitted by or for the Customer to
          Swiftforms or collected and processed by or for the Customer using
          Swiftforms’ services.
        </p>
        <p>
          The parties agree that the obligations under this DPA that are
          specific to the GDPR shall not apply until the GDPR has come into full
          force and effect.
        </p>
        <p>
          <b>1. Definitions</b>
        </p>
        <p>1.1. The following definitions are used in this DPA:</p>
        <p>
          a) <b>“Adequate Country” </b>means a country or territory that is
          recognised under EU Data Protection Laws as providing adequate
          protection for Personal Data;
        </p>
        <p>
          b) <b>“Affiliate” </b>means, with respect to a party, any corporate
          entity that, directly or indirectly, Controls, is Controlled by, or is
          under Common Control with such party (but only for so long as such
          Control exists);
        </p>
        <p>
          c) <b>“Swiftforms Group” </b>means Swiftforms and any of its
          Affiliates;
        </p>
        <p>
          d) <b>“Customer Group” </b>means the Customer and any of its
          Affiliates established and/or doing business in the EEA, or UK;
        </p>
        <p>
          e) <b>“EU Data Protection Laws” </b>means EU Directive 95/46/EC, as
          transposed into domestic legislation of each Member State and as
          amended, replaced or superseded from time to time, including by the
          GDPR and laws implementing or supplementing the GDPR ;
        </p>
        <p>
          f) <b>“GDPR” </b>means the General Data Protection Regulation
          (Regulation (EU) 2016/679 of the European Parliament and of the
          Council of 27 April 2016 on the protection of natural persons with
          regard to the processing of personal data and on the free-flow of
          data);
        </p>
        <p>
          g) <b>“Personal Data” </b>means all data which is defined as ‘personal
          data’ under EU Data Protection Laws and to which EU Data Protection
          Laws apply and which is provided by the Customer to Swiftforms, and
          accessed, stored or otherwise processed by Swiftforms as a data
          processor as part of its provision of the Service to the Customer; and
        </p>
        <p>
          h) <b>“Processing”</b>, <b>“data controller”</b>,{" "}
          <b>“data subject”</b>, <b>“supervisory authority” </b>and{" "}
          <b>“data processor” </b>shall have the meanings ascribed to them in EU
          Data Protection Laws.
        </p>
        <p>
          1.2 An entity <b>“Controls” </b>another entity if it: (a) holds a
          majority of the voting rights in it; (b) is a member or shareholder of
          it and has the right to remove a majority of its board of directors or
          equivalent managing body; (c) is a member or shareholder of it and
          controls alone or pursuant to an agreement with other shareholders or
          members, a majority of the voting rights in it; or (d) has the right
          to exercise a dominant influence over it pursuant to its
          constitutional documents or pursuant to a contract; and two entities
          are treated as being in <b>“Common Control” </b>if either controls the
          other (directly or indirectly) or both are controlled (directly or
          indirectly) by the same entity.
        </p>
        <p>
          <b>2. Status of the parties</b>
        </p>
        <p>
          2.1 The type of Personal Data processed pursuant to this DPA and the
          subject matter, duration, nature and purpose of the processing, and
          the categories of data subjects, are described in Annex 1.
        </p>
        <p>
          2.2 Each party warrants in relation to Personal Data that it will
          comply (and will procure that any of its personnel comply and use
          commercially reasonable efforts to procure that its sub-processors’
          comply), with EU Data Protection Laws. As between the parties, the
          Customer shall have sole responsibility for the accuracy, quality, and
          legality of Personal Data and the means by which the Customer acquired
          Personal Data.
        </p>
        <p>
          2.3 In respect of the parties’ rights and obligations under this DPA
          regarding the Personal Data, the parties hereby acknowledge and agree
          that the Customer is the data controller and Swiftforms is the data
          processor, and accordingly Swiftforms agrees that it shall process all
          Personal Data in accordance with its obligations pursuant to this DPA.
        </p>
        <p>
          2.4 Where and to the extent that Swiftforms processes data which is
          defined as ‘<i>personal data’</i> under EU Data Protection Laws as a
          data controller as set out in the Swiftforms Privacy Policy (available
          at{" "}
          <a href="https://www.appenate.com/privacy-policy">
            https://www.Swiftforms.com/privacy-policy
          </a>
          ), Swiftforms will comply with applicable EU Data Protection Laws in
          respect of that processing.
        </p>
        <p>
          2.5 Each party shall appoint an individual within its organisation
          authorised to respond from time to time to enquiries regarding the
          Personal Data and each party shall deal with such enquiries promptly.
        </p>
        <p>
          <b>3. Swiftforms obligations</b>
        </p>
        <p>
          3.1 With respect to all Personal Data, Swiftforms warrants that it
          shall:
        </p>
        <p>
          (a) only process Personal Data in order to provide the Service, and
          shall act only in accordance with: (i) this DPA, (ii) the Customer’s
          written instructions as represented by the Main Agreement and this
          DPA, and (iii) as required by applicable laws;
        </p>
        <p>
          (b) as soon as reasonably practicable upon becoming aware, inform the
          Customer if, in Swiftforms’ opinion, any instructions provided by the
          Customer under clause 3.1(a) infringe the GDPR;
        </p>
        <p>
          (c) implement appropriate technical and organisational measures to
          ensure a level of security appropriate to the risks that are presented
          by the processing of Personal Data, in particular protection against
          accidental or unlawful destruction, loss, alteration, unauthorised
          disclosure of, or access to Personal Data. Such measures include,
          without limitation, the security measures set out in Annex 3;
        </p>
        <p>
          (d) take reasonable steps to ensure that only authorised personnel
          have access to such Personal Data and that any persons whom it
          authorises to have access to the Personal Data are under obligations
          of confidentiality;
        </p>
        <p>
          (e) as soon as reasonably practicable upon becoming aware, notify the
          Customer of any breach of security leading to the accidental loss,
          destruction or damage or unlawful processing, alteration, unauthorised
          disclosure of, or access to, Personal Data transmitted, stored or
          otherwise processed by Swiftforms, its sub-processors, or any other
          identified or unidentified third party (a <b>“Security Breach”</b>);
        </p>
        <p>
          (f) promptly provide the Customer with reasonable cooperation and
          assistance in respect of a Security Breach and all reasonable
          information in Swiftforms’ possession concerning such Security Breach
          insofar as it affects the Customer, including the following to the
          extent then known:
        </p>
        <p>
          (i) the possible cause and consequences for the Data Subjects of the
          Security Breach;
        </p>
        <p>(ii) the categories of Personal Data involved;</p>
        <p>
          (iii) a summary of the possible consequences for the relevant data
          subjects;
        </p>
        <p>
          (iv) a summary of the unauthorised recipients of the Personal Data;
          and
        </p>
        <p>
          (v) the measures taken by Swiftforms to mitigate any damage and
          adverse effects ;
        </p>
        <p>
          (g) not make any public announcement about a Security Breach (a{" "}
          <b>“Breach Notice”</b>) without the prior written consent of the
          Customer, unless required by applicable law;
        </p>
        <p>
          (h) promptly notify the Customer if it receives a request from a data
          subject to access, rectify or erase that individual’s Personal Data,
          or if a data subject objects to the processing of, or makes a data
          portability request in respect of, such Personal Data (each a{" "}
          <b>“Data Subject Request”</b>). Swiftforms shall not respond to a Data
          Subject Request without the Customer’s prior written consent except to
          confirm that such request relates to the Customer, to which the
          Customer hereby agrees. To the extent that the Customer does not have
          the ability to address a Data Subject Request, then upon the
          Customer’s request Swiftforms shall provide reasonable assistance to
          the Customer to facilitate such Data Subject Request to the extent
          able and in line with applicable law. The Customer shall cover all
          costs incurred by Swiftforms in connection with its provision of such
          assistance;
        </p>
        <p>
          (i) other than to the extent required to comply with applicable law,
          as soon as reasonably practicable following termination or expiry of
          the Main Agreement or completion of the Service, Swiftforms will
          delete all Personal Data (including copies thereof) processed pursuant
          to this DPA;
        </p>
        <p>
          j) taking into account the nature of processing and the information
          available to Swiftforms, provide such assistance to the Customer as
          the Customer reasonably requests in relation to Swiftforms’
          obligations under EU Data Protection Laws with respect to:
        </p>
        <p>
          (i) data protection impact assessments (as such term is defined in the
          GDPR);
        </p>
        <p>
          (ii) notifications to the supervisory authority under EU Data
          Protection Laws and/or communications to data subjects by the Customer
          in response to any Security Breach; and
        </p>
        <p>
          (iii) the Customer’s compliance with its obligations under the GDPR
          with respect to the security of processing; provided that the Customer
          shall cover all costs incurred by Swiftforms in connection with its
          provision of such assistance.
        </p>
        <p>
          <b>4. Sub-processing</b>
        </p>
        <p>
          4.1 The Customer grants a general authorisation to Swiftforms to
          appoint third party data center operators, and outsourced marketing,
          business, engineering and customer support providers as sub-processors
          to support the performance of the Service.
        </p>
        <p>
          4.2 Swiftforms shall maintain a list of current sub-processors for the
          Service, including a history of revisions, the identities of those
          sub-processors and their country of location at{" "}
          <a href="https://www.appenate.com/sub-processors">
            https://www.Swiftforms.com/sub-processors
          </a>
          . Upon the appointment of new or replacement of sub-processor(s),
          Swiftforms shall update the list of current sub-processors and shall
          send email notification to the Customer prior to the new or
          replacement sub-processor(s) processing any Personal Data in
          connection with the provision of the Service.
        </p>
        <p>
          (a) If the Customer has a reasonable objection to any new or
          replacement sub-processor, it shall notify Swiftforms of such
          objections in writing within ten (10) days of the notification and the
          parties will seek to resolve the matter in good faith. If Swiftforms
          is reasonably able to provide the Service to the Customer in
          accordance with the Main Agreement without using the sub-processor and
          decides in its discretion to do so, then the Customer will have no
          further rights under this clause 4.2 in respect of the proposed use of
          the sub-processor. If Swiftforms requires use of the sub-processor in
          its discretion and is unable to satisfy the Customer as to the
          suitability of the sub-processor or the documentation and protections
          in place between Swiftforms and the sub-processor within ninety (90)
          days from the Customer’s notification of objections, the Customer may
          within thirty (30) days following the end of the ninety (90) day
          period referred to above, terminate the applicable Service
          subscription(s) with at least thirty (30) days written notice, solely
          with respect to the service(s) to which the proposed new
          sub-processor’s processing of Personal Data relates. If the Customer
          does not provide a timely objection to any new or replacement
          sub-processor in accordance with this clause 4.2, the Customer will be
          deemed to have consented to the sub-processor and waived its right to
          object. Swiftforms may use a new or replacement sub-processor whilst
          the objection procedure in this clause 4.2 is in process.
        </p>
        <p>
          4.3 Swiftforms will ensure that any sub-processor it engages to
          provide an aspect of the Service on its behalf in connection with this
          DPA does so only on the basis of a written contract which imposes on
          such sub-processor terms substantially no less protective of Personal
          Data than those imposed on Swiftforms in this DPA (the{" "}
          <b>“Relevant Terms”</b>).
        </p>
        <p>
          <b>5. Audit and records</b>
        </p>
        <p>
          5.1 Swiftforms shall, in accordance with EU Data Protection Laws, make
          available to the Customer such information in Swiftforms’ possession
          or control as the Customer may reasonably request with a view to
          demonstrating Swiftforms’ compliance with the obligations of data
          processors under EU Data Protection Law in relation to its processing
          of Personal Data.
        </p>
        <p>
          5.2 The Customer may exercise its right of audit under EU Data
          Protection Laws in relation to Personal Data, through Swiftforms
          providing:
        </p>
        <p>
          (a) an audit report not older than eighteen (18) months, prepared by
          an independent external auditor demonstrating that Swiftforms’
          technical and organisational measures are sufficient and in accordance
          with an accepted industry audit standard; and
        </p>
        <p>
          b) additional information in Swiftforms’ possession or control to an
          EU supervisory authority when it requests or requires additional
          information in relation to the processing of Personal Data carried out
          by Swiftforms under this DPA.
        </p>
        <p>
          <b>6. Data transfers</b>
        </p>
        <p>
          6.1 To the extent any processing of Personal Data by Swiftforms takes
          place in any country outside the EEA (except if in an Adequate
          Country), the parties agree that the standard contractual clauses
          approved by the EU authorities under EU Data Protection Laws and set
          out in Annex 2 will apply in respect of that processing, and
          Swiftforms will comply with the obligations of the ‘data importer’ in
          the standard contractual clauses and the Customer will comply with the
          obligations of the ‘data exporter’.
        </p>
        <p>
          6.2 The Customer acknowledges and accepts that the provision of the
          Service under the Main Agreement may require processing of Personal
          Data by sub-processors in countries outside the EEA.
        </p>
        <p>
          6.3 If, in the performance of this DPA, Swiftforms transfers any
          Personal Data to a sub-processor located outside of the EEA (without
          prejudice to clause 4), Swiftforms shall in advance of any such
          transfer ensure that a legal mechanism to achieve adequacy in respect
          of that processing is in place, such as:
        </p>
        <p>
          (a) the requirement for Swiftforms to execute or procure that the
          sub-processor execute to the benefit of the Customer standard
          contractual clauses approved by the EU authorities under EU Data
          Protection Laws and set out in Annex 2;
        </p>
        <p>
          (b) the requirement for the sub-processor to be certified under the
          EU-U.S. Privacy Shield Framework; or
        </p>
        <p>
          (c) the existence of any other specifically approved safeguard for
          data transfers (as recognised under EU Data Protection Laws) and/or a
          European Commission finding of adequacy.
        </p>
        <p>
          6.4 The following terms shall apply to the standard contractual
          clauses set out in Annex 2:
        </p>
        <p>
          (a) The Customer may exercise its right of audit under clause 5.1(f)
          of the standard contractual clauses as set out in, and subject to the
          requirements of, clause 5.2 of this DPA; and
        </p>
        <p>
          (b) Swiftforms may appoint sub-processors as set out, and subject to
          the requirements of, clauses 4 and 6.3 of this DPA.
        </p>
        <p>
          <b>7. General</b>
        </p>
        <p>
          7.1 This DPA is without prejudice to the rights and obligations of the
          parties under the Main Agreement which shall continue to have full
          force and effect. In the event of any conflict between the terms of
          this DPA and the terms of the Main Agreement, the terms of this DPA
          shall prevail so far as the subject matter concerns the processing of
          Personal Data.
        </p>
        <p>
          7.2 Swiftforms’ liability under or in connection with this DPA
          (including under the standard contractual clauses set out in Annex 2)
          is subject to the limitations on liability contained in the Main
          Agreement.
        </p>
        <p>
          7.3 This DPA does not confer any third-party beneficiary rights, it is
          intended for the benefit of the parties hereto and their respective
          permitted successors and assigns only, and is not for the benefit of,
          nor may any provision hereof be enforced by, any other person.
        </p>
        <p>
          F7.4 With the exemption of clauses 7 and 9 in Annex 2, this DPA and
          any action related thereto shall be governed by and construed in
          accordance with the laws of Scotland, United Kingdom, without giving
          effect to any conflicts of laws principles.&nbsp; With the exemption
          of the jurisdiction clauses in Annex 2, the parties consent to the
          personal jurisdiction of, and venue in, the courts of Scotland, United
          Kingdom.
        </p>
        <p>
          7.5 This DPA is the final, complete and exclusive agreement of the
          parties with respect to the subject matter hereof and supersedes and
          merges all prior discussions and agreements between the parties with
          respect to such subject matter. Other than in respect of statements
          made fraudulently, no other representations or terms shall apply or
          form part of this DPA. Each party represents and warrants to the other
          that the execution and delivery of this DPA, and the performance of
          such party’s obligations hereunder, have been duly authorised and that
          this DPA is a valid and legally binding agreement on each such party,
          enforceable in accordance with its terms.
        </p>
        <p>
          <b>Annex 1 – Details of Personal Data and Processing Activities</b>
        </p>
        <p>
          <i>
            This Annex provides details of the Processing of Personal Data as
            required by Article 28(3) GDPR.
          </i>
        </p>
        <p>
          (a) The personal data comprises: in relation to visitors of the
          Customer’s online properties identification data, professional life
          data, personal life data, connection data, or localisation data
          (including IP addresses). The Customer, its online visitors and/or
          other partners may also upload content to the Customer’s online
          properties which may include personal data and special categories of
          data, the extent of which is determined and controlled by the Customer
          in its sole discretion.
          <br />
          Such special categories of data include, but may not be limited to,
          information revealing racial or ethnic origins, political opinions,
          religious or philosophical beliefs, trade-union membership, and the
          processing of data concerning an individual’s health or sexual and
          gender orientation.
        </p>
        <p>
          (b) The duration of the processing will be: until the earliest of (i)
          expiry/termination of the Main Agreement, or (ii) the date upon which
          processing is no longer necessary for the purposes of either party
          performing its obligations under the Main Agreement (to the extent
          applicable);
        </p>
        <p>
          (c) The processing will comprise: Only processing necessary to provide
          the Service to the Customer, pursuant to the Main Agreement;
        </p>
        <p>
          (d) The purpose(s) of the processing is/are: necessary for the
          provision of the Service;
        </p>
        <p>(e) Personal data may concern the following data subjects:</p>
        <ul>
          <li>
            Prospective customers, customers, resellers, referrers, business
            partners, and vendors of the Customer (who are natural persons);
          </li>
          <li>
            Employees or contact persons of the Customer’s prospective
            customers, customers, resellers, referrers, sub-processors, business
            partners, and vendors (who are natural persons);
          </li>
          <li>
            Employees, agents, advisors, and freelancers of the Customer (who
            are natural persons); and/or
          </li>
          <li>
            Natural persons authorised by the Customer to use the Service.
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>
          <b>Annex 2 – Standard Contractual Clauses (processors)</b>
        </p>
        <p>
          The European Commission decision 2010/87/EU sets out the following
          standard contractual clauses for the transfer of personal data to data
          processors established in third countries which do not ensure an
          adequate level of data protection.
        </p>
        <p>
          <b>INTRODUCTION</b>
        </p>
        <p>
          Both parties have agreed on the following Contractual Clauses (the{" "}
          <b>“Clauses”</b>) in order to adduce adequate safeguards with respect
          to the protection of privacy and fundamental rights and freedoms of
          individuals for the transfer by the data exporter to the data importer
          of the personal data specified in Appendix 1.
        </p>
        <p>
          <b>AGREED TERMS</b>
        </p>
        <p>
          <b>1. Definitions</b>
        </p>
        <p>For the purposes of the Clauses:</p>
        <p>
          a) <b>“personal data”</b>, <b>“special categories of data”</b>,{" "}
          <b>“process/processing”</b>, <b>“controller”</b>, <b>“processor”</b>,{" "}
          <b>“data subject” </b>and <b>“supervisory authority” </b>shall have
          the same meaning as in EU Data Protection Laws 95/46/EC of the
          European Parliament and of the Council of 24 October 1995 on the
          protection of individuals with regard to the processing of personal
          data and on the free movement of such data;
        </p>
        <p>
          b) the <b>“data exporter” </b>means the entity who transfers the
          personal data;
        </p>
        <p>
          c) the <b>“data importer” </b>means the processor who agrees to
          receive from the data exporter personal data intended for processing
          on his behalf after the transfer in accordance with his instructions
          and the terms of the Clauses and who is not subject to a third
          country’s system ensuring adequate protection within the meaning of
          Article 25(1) of EU Data Protection Laws 95/46/EC;
        </p>
        <p>
          d) the <b>“sub-processor” </b>means any processor engaged by the data
          importer or by any other sub-processor of the data importer who agrees
          to receive from the data importer or from any other sub-processor of
          the data importer personal data exclusively intended for processing
          activities to be carried out on behalf of the data exporter after the
          transfer in accordance with his instructions, the terms of the Clauses
          and the terms of the written subcontract;
        </p>
        <p>
          e) the <b>“Applicable Laws”</b> means (a) European Union or Member
          State laws with respect to any Company Personal Data in respect of
          which any Company Group Member is subject to EU Data Protection Laws;
          and (b) any other applicable law with respect to any Company Personal
          Data in respect of which any Company Group Member is subject to any
          other Data Protection Laws; and
        </p>
        <p>
          f) <b>“technical and organisational security measures” </b>means those
          measures aimed at protecting personal data against accidental or
          unlawful processing or accidental loss, alteration, unauthorised
          disclosure or access, in particular where the processing involves the
          transmission of data over a network, and against all other unlawful
          forms of processing.
        </p>
        <p>
          <b>2. Details of the transfer</b>
        </p>
        <p>
          The details of the transfer and in particular the special categories
          of personal data where applicable are specified in Appendix 1 which
          forms an integral part of the Clauses.
        </p>
        <p>
          <b>3. Third-party beneficiary clause</b>
        </p>
        <p>
          3.1 The data subject can enforce against the data exporter this
          Clause, Clause 4.1(b) to (i), Clause 5(a) to (e), and (g) to (j),
          Clause 6(1) and (2), Clause 7, Clause 8(2), and Clauses 9 to 12 as
          third-party beneficiary.
        </p>
        <p>
          3.2 The data subject can enforce against the data importer this
          Clause, Clause 5(a) to (e) and (g), Clause 6, Clause 7, Clause 8(2),
          and Clauses 9 to 12, in cases where the data exporter has factually
          disappeared or has ceased to exist in law unless any successor entity
          has assumed the entire legal obligations of the data exporter by
          contract or by operation of law, as a result of which it takes on the
          rights and obligations of the data exporter, in which case the data
          subject can enforce them against such entity.
        </p>
        <p>
          3.3 The data subject can enforce against the sub-processor this
          Clause, Clause 5(a) to (e) and (g), Clause 6, Clause 7, Clause 8(2),
          and Clauses 9 to 12, in cases where both the data exporter and the
          data importer have factually disappeared or ceased to exist in law or
          have become insolvent, unless any successor entity has assumed the
          entire legal obligations of the data exporter by contract or by
          operation of law as a result of which it takes on the rights and
          obligations of the data exporter, in which case the data subject can
          enforce them against such entity. Such third-party liability of the
          sub- processor shall be limited to its own processing operations under
          the Clauses.
        </p>
        <p>
          3.4 The parties do not object to a data subject being represented by
          an association or other body if the data subject so expressly wishes
          and if permitted by national law.
        </p>
        <p>
          <b>4. Obligations of the data exporter</b>
        </p>
        <p>The data exporter agrees and warrants:</p>
        <p>
          a) that the processing, including the transfer itself, of the personal
          data has been and will continue to be carried out in accordance with
          the relevant provisions of the applicable data protection law (and,
          where applicable, has been notified to the relevant authorities of the
          Member State where the data exporter is established) and does not
          violate the relevant provisions of that State;
        </p>
        <p>
          b) that it has instructed and throughout the duration of the personal
          data-processing services will instruct the data importer to process
          the personal data transferred only on the data exporter’s behalf and
          in accordance with the applicable data protection law and the Clauses;
        </p>
        <p>
          c) that the data importer will provide sufficient guarantees in
          respect of the technical and organisational security measures
          specified in Appendix 3 to this contract;
        </p>
        <p>
          d) that after assessment of the requirements of the applicable data
          protection law, the security measures are appropriate to protect
          personal data against accidental or unlawful destruction or accidental
          loss, alteration, unauthorised disclosure or access, in particular
          where the processing involves the transmission of data over a network,
          and against all other unlawful forms of processing, and that these
          measures ensure a level of security appropriate to the risks presented
          by the processing and the nature of the data to be protected having
          regard to the state of the art and the cost of their implementation;
        </p>
        <p>e) that it will ensure compliance with the security measures;</p>
        <p>
          f) that, if the transfer involves special categories of data, the data
          subject has been informed or will be informed before, or as soon as
          possible after, the transfer that its data could be transmitted to a
          third country not providing adequate protection within the meaning of
          EU Data Protection Laws 95/46/EC;
        </p>
        <p>
          g) to forward any notification received from the data importer or any
          sub-processor pursuant to Clause 5(b) and Clause 8(3) to the data
          protection supervisory authority if the data exporter decides to
          continue the transfer or to lift the suspension;
        </p>
        <p>
          h) to make available to the data subjects upon request a copy of the
          Clauses, with the exception of Appendix 2, and a summary description
          of the security measures, as well as a copy of any contract for
          sub-processing services which has to be made in accordance with the
          Clauses, unless the Clauses or the contract contain commercial
          information, in which case it may remove such commercial information;
        </p>
        <p>
          i) that, in the event of sub-processing, the processing activity is
          carried out in accordance with Clause 11 by a sub-processor providing
          at least the same level of protection for the personal data and the
          rights of data subject as the data importer under the Clauses; and
        </p>
        <p>j) that it will ensure compliance with Clause 4(a) to (i).</p>
        <p>
          <b>5. Obligations of the data importer</b>
        </p>
        <p>The data importer agrees and warrants:</p>
        <p>
          a) to process the personal data only on behalf of the data exporter
          and in compliance with its instructions and the Clauses; if it cannot
          provide such compliance for whatever reasons, it agrees to inform
          promptly the data exporter of its inability to comply, in which case
          the data exporter is entitled to suspend the transfer of data and/or
          terminate the contract;
        </p>
        <p>
          b) that it has no reason to believe that the legislation applicable to
          it prevents it from fulfilling the instructions received from the data
          exporter and its obligations under the contract and that in the event
          of a change in this legislation which is likely to have a substantial
          adverse effect on the warranties and obligations provided by the
          Clauses, it will promptly notify the change to the data exporter as
          soon as it is aware, in which case the data exporter is entitled to
          suspend the transfer of data and/or terminate the contract;
        </p>
        <p>
          c) that it has implemented the technical and organisational security
          measures specified in Appendix 3 before processing the personal data
          transferred;
        </p>
        <p>d) that it will promptly notify the data exporter about:</p>
        <p>
          (i) any legally binding request for disclosure of the personal data by
          a law enforcement authority unless otherwise prohibited, such as a
          prohibition under criminal law to preserve the confidentiality of a
          law enforcement investigation;
        </p>
        <p>(ii) any accidental or unauthorised access; and</p>
        <p>
          (iii) any request received directly from the data subjects without
          responding to that request, unless it has been otherwise authorised to
          do so;
        </p>
        <p>
          e) to deal promptly and properly with all inquiries from the data
          exporter relating to its processing of the personal data subject to
          the transfer and to abide by the advice of the supervisory authority
          with regard to the processing of the data transferred;
        </p>
        <p>
          f) at the request of the data exporter to submit its data-processing
          facilities for audit of the processing activities covered by the
          Clauses which shall be carried out by the data exporter or an
          inspection body composed of independent members and in possession of
          the required professional qualifications bound by a duty of
          confidentiality, selected by the data exporter, where applicable, in
          agreement with the supervisory authority;
        </p>
        <p>
          g) to make available to the data subject upon request a copy of the
          Clauses, or any existing contract for sub-processing, unless the
          Clauses or contract contain commercial information, in which case it
          may remove such commercial information, with the exception of Appendix
          2 which shall be replaced by a summary description of the security
          measures in those cases where the data subject is unable to obtain a
          copy from the data exporter;
        </p>
        <p>
          h) that, in the event of sub-processing, it has previously informed
          the data exporter and obtained its prior written consent;
        </p>
        <p>
          i) that the processing services by the sub-processor will be carried
          out in accordance with Clause 11;
        </p>
        <p>
          j) to send promptly a copy of any sub-processor agreement it concludes
          under the Clauses to the data exporter.
        </p>
        <p>
          <b>6. Liability</b>
        </p>
        <p>
          6.1 The parties agree that any data subject, who has suffered damage
          as a result of any breach of the obligations referred to in Clause 3
          or in Clause 11 by any party or sub-processor is entitled to receive
          compensation from the data exporter for the damage suffered.
        </p>
        <p>
          6.2 If a data subject is not able to bring a claim for compensation in
          accordance with paragraph 1 against the data exporter, arising out of
          a breach by the data importer or his sub-processor of any of their
          obligations referred to in Clause 3 or in Clause 11, because the data
          exporter has factually disappeared or ceased to exist in law or has
          become insolvent, the data importer agrees that the data subject may
          issue a claim against the data importer as if it were the data
          exporter, unless any successor entity has assumed the entire legal
          obligations of the data exporter by contract or by operation of law,
          in which case the data subject can enforce its rights against such
          entity. The data importer may not rely on a breach by a sub-processor
          of its obligations in order to avoid its own liabilities.
        </p>
        <p>
          6.3 If a data subject is not able to bring a claim against the data
          exporter or the data importer referred to in paragraphs 1 and 2,
          arising out of a breach by the sub-processor of any of their
          obligations referred to in Clause 3 or in Clause 11 because both the
          data exporter and the data importer have factually disappeared or
          ceased to exist in law or have become insolvent, the sub-processor
          agrees that the data subject may issue a claim against the data
          sub-processor with regard to its own processing operations under the
          Clauses as if it were the data exporter or the data importer, unless
          any successor entity has assumed the entire legal obligations of the
          data exporter or data importer by contract or by operation of law, in
          which case the data subject can enforce its rights against such
          entity. The liability of the sub-processor shall be limited to its own
          processing operations under the Clauses.
        </p>
        <p>
          <b>7. Mediation and jurisdiction</b>
        </p>
        <p>
          7.1 The data importer agrees that if the data subject invokes against
          it third-party beneficiary rights and/or claims compensation for
          damages under the Clauses, the data importer will accept the decision
          of the data subject:
        </p>
        <p>
          a) to refer the dispute to mediation, by an independent person or,
          where applicable, by the supervisory authority;
        </p>
        <p>
          b) to refer the dispute to the courts in the Member State in which the
          data exporter is established.
        </p>
        <p>
          7.2 The parties agree that the choice made by the data subject will
          not prejudice its substantive or procedural rights to seek remedies in
          accordance with other provisions of national or international law.
        </p>
        <p>
          <b>8. Cooperation with supervisory authorities</b>
        </p>
        <p>
          8.1 The data exporter agrees to deposit a copy of this contract with
          the supervisory authority if it so requests or if such deposit is
          required under the applicable data protection law.
        </p>
        <p>
          8.2 The parties agree that the supervisory authority has the right to
          conduct an audit of the data importer, and of any sub-processor, which
          has the same scope and is subject to the same conditions as would
          apply to an audit of the data exporter under the applicable data
          protection law.
        </p>
        <p>
          8.3 The data importer shall promptly inform the data exporter about
          the existence of legislation applicable to it or any sub-processor
          preventing the conduct of an audit of the data importer, or any
          sub-processor, pursuant to paragraph 2. In such a case the data
          exporter shall be entitled to take the measures foreseen in Clause
          5(b).
        </p>
        <p>
          <b>9. Governing law</b>
        </p>
        <p>
          The Clauses shall be governed by the laws of the Member State in which
          the data exporter is established.
        </p>
        <p>
          <b>10. Variation of the contract</b>
        </p>
        <p>
          The parties undertake not to vary or modify the Clauses. This does not
          preclude the parties from adding clauses on business related issues
          where required as long as they do not contradict the Clause.
        </p>
        <p>
          <b>11. Sub-processing</b>
        </p>
        <p>
          11.1 The data importer shall not subcontract any of its processing
          operations performed on behalf of the data exporter under the Clauses
          without the prior written consent of the data exporter. Where the data
          importer subcontracts its obligations under the Clauses, with the
          consent of the data exporter, it shall do so only by way of a written
          agreement with the sub-processor which imposes the same obligations on
          the sub-processor as are imposed on the data importer under the
          Clauses. Where the sub-processor fails to fulfil its data protection
          obligations under such written agreement the data importer shall
          remain fully liable to the data exporter for the performance of the
          sub-processor’s obligations under such agreement.
        </p>
        <p>
          11.2 The prior written contract between the data importer and the
          sub-processor shall also provide for a third party beneficiary clause
          as laid down in Clause 3 for cases where the data subject is not able
          to bring the claim for compensation referred to in paragraph 1 of
          Clause 6 against the data exporter or the data importer because they
          have factually disappeared or have ceased to exist in law or have
          become insolvent and no successor entity has assumed the entire legal
          obligations of the data exporter or data importer by contract or by
          operation of law.
          <br />
          Such third-party liability of the sub-processor shall be limited to
          its own processing operations under the Clauses.
        </p>
        <p>
          11.3 The provisions relating to data protection aspects for
          sub-processing of the contract referred to in paragraph 1 shall be
          governed by the law of the Member State in which the data exporter is
          established.
        </p>
        <p>
          11.4 The data exporter shall keep a list of sub-processing agreements
          concluded under the Clauses and notified by the data importer pursuant
          to Clause 5.1(j), which shall be updated at least once a year. The
          list shall be available to the data exporter’s data protection
          supervisory authority.
        </p>
        <p>
          <b>
            12. Obligation after the termination of personal data-processing
            services
          </b>
        </p>
        <p>
          12.1 The parties agree that on the termination of the provision of
          data-processing services, the data importer and the sub-processor
          shall, at the choice of the data exporter, return all the personal
          data transferred and the copies thereof to the data exporter or shall
          destroy all the personal data and certify to the data exporter that it
          has done so, unless legislation imposed upon the data importer
          prevents it from returning or destroying all or part of the personal
          data transferred. In that case, the data importer warrants that it
          will guarantee the confidentiality of the personal data transferred
          and will not actively process the personal data transferred anymore.
        </p>
        <p>
          12.2 The data importer and the sub-processor warrant that upon request
          of the data exporter and/or of the supervisory authority, it will
          submit its data-processing facilities for an audit of the measures
          referred to in paragraph 1.
        </p>
        <p>
          <b>Appendix 1 to the Standard Contractual Clauses</b>
        </p>
        <p>
          This Appendix forms part of the Clauses and&nbsp;is agreed by the
          parties.
          <br />
          The Member States may complete or specify, according to their national
          procedures, any additional necessary information to be contained in
          this Appendix.
        </p>
        <p>
          <b>
            Data exporter
            <br />
          </b>
          The data exporter is:
          <br />
          the (i) legal entity that has created an account with Swiftforms Pty
          Ltd (“<b>Swiftforms”</b>) for the provision of the Service, and
          executed the Clauses as a data exporter and, (ii) all affiliates of
          such entity established within the EEA, which have purchased services
          from Swiftforms or its Affiliates.
        </p>
        <p>
          <b>
            Data importer
            <br />
          </b>
          The data importer is: Swiftforms
          <br />
          which processes Personal Data upon the instruction of the data
          exporter in accordance with the terms of the Main Agreement between
          the data exporter and Swiftforms.
        </p>
        <p>
          <b>
            Data subjects
            <br />
          </b>
          The personal data transferred concern the following categories of data
          subjects:
          <br />
          The data exporter may submit Personal Data to Swiftforms and its
          Affiliates, the extent of which is determined and controlled by the
          data exporter in its sole discretion, and which may include, but is
          not limited to Personal Data relating to the following categories of
          data subjects:
        </p>
        <ul>
          <li>
            Prospective customers, customers, resellers, referrers, business
            partners, and vendors of the data exporter (who are natural
            persons);
          </li>
          <li>
            Employees or contact persons of the data exporter’s prospective
            customers, customers, resellers, referrers, subcontractors, business
            partners, and vendors (who are natural persons);
          </li>
          <li>
            Employees, agents, advisors, and freelancers of the data exporter
            (who are natural persons); and/or
          </li>
          <li>
            Natural persons authorised by the data exporter to use the services
            provided by Swiftforms to the data exporter.
          </li>
        </ul>
        <p>
          <b>
            Categories of data
            <br />
          </b>
          The personal data transferred concern the following categories of
          data:
          <br />
          The data exporter may submit Personal Data to Swiftforms and its
          Affiliates, the extent of which is determined and controlled by the
          data exporter in its sole discretion, and which may include, but is
          not limited to, the following categories of Personal Data:
        </p>
        <ul>
          <li>
            Names, titles, position, employer, contact information (email,
            phone, fax, physical address etc.), identification data,
            professional life data, personal life data, connection data, or
            localisation data (including IP addresses).
          </li>
        </ul>
        <p>
          <b>
            Special categories of data (if appropriate)
            <br />
          </b>
          The personal data transferred concern the following special categories
          of data:
          <b>
            <br />
          </b>
          The data exporter may submit special categories of data to Swiftforms
          and its Affiliates, the extent<b> </b>of which is determined and
          controlled by the data exporter in its sole discretion. Such special
          <b> </b>categories of data include, but may not be limited to,
          Personal Data with information revealing<b> </b>racial or ethnic
          origins, political opinions, religious or philosophical beliefs,
          trade-union<b> </b>membership, and the processing of data concerning
          an individual’s health or sexual and gender<b> </b>orientation.
        </p>
        <p>
          <b>
            Processing operations
            <br />
          </b>
          The personal data transferred will be subject to the following basic
          processing activities:
          <b>
            <br />
          </b>
          The objective of the processing of Personal Data by Swiftforms is to
          provide the Service, pursuant<b> </b>to the Main Agreement.
        </p>
        <p>
          <b>Appendix 2 to the Standard Contractual Clauses</b>
        </p>
        <p>
          This Appendix forms part of the Clauses and&nbsp;is agreed by the
          parties.
        </p>
        <p>
          <b>
            Description of the technical and organisational security measures
            implemented by the data importer in accordance with Clauses 4(d) and
            5(c):
          </b>
        </p>
        <p>See Annex 3</p>
        <p>
          <b>Annex 3 – Security Measures</b>
        </p>
        <p>
          Swiftforms (the data importer) currently observes the security
          practices described in this Annex 3. Notwithstanding any provision to
          the contrary otherwise agreed to by data exporter, Swiftforms may
          modify or update these practices at its discretion provided that such
          modification and update does not result in a material degradation in
          the protection offered by these practices.
        </p>
        <p>
          <b>Access Control: Preventing Unauthorised Service Access</b>
        </p>
        <ul>
          <li>
            Outsourced processing: Swiftforms hosts the Service with outsourced
            cloud infrastructure providers. Additionally, Swiftforms maintains
            contractual relationships with vendors in order to provide the
            Service in accordance with our Data Processing Addendum. Swiftforms
            relies on contractual agreements, privacy policies, and vendor
            compliance programs in order to protect data processed or stored by
            these vendors.
          </li>
          <li>
            Physical and environmental security: Swiftforms hosts its Service
            infrastructure with multi-tenant, outsourced infrastructure
            providers. The physical and environmental security controls are
            audited for SOC 2 Type II and ISO 27001 compliance, among other
            certifications.
          </li>
          <li>
            Authentication: Customers who interact with the Service via the user
            interface must authenticate before accessing non-public customer
            data. As of 25 May 2018, Swiftforms offers several password policy
            options for customer accounts which can apply strong password
            requirements to login credentials.
          </li>
          <li>
            Authorisation: Customer data is stored in multi-tenant storage
            systems accessible to Customers only via application user interfaces
            and application programming interfaces. Customers are not allowed
            direct access to the underlying application infrastructure. The
            authorisation model for the Service is designed to ensure that only
            the appropriately assigned individuals can access relevant features,
            views, and customisation options. Authorisation to data sets is
            performed through validating the user’s permissions against the
            attributes associated with each data set.
          </li>
          <li>
            Application Programming Interface (API): Our public Service APIs are
            accessed by using an API key, which is only available to Service
            administrator users.
          </li>
        </ul>
        <p>
          <b>Access Control:&nbsp; Preventing Unauthorised Service Use</b>
        </p>
        <p>
          Swiftforms implements industry standard access controls and detection
          capabilities for the internal networks that support its products.
        </p>
        <ul>
          <li>
            Access controls: Network access control mechanisms are designed to
            prevent network traffic using unauthorised protocols from reaching
            the product infrastructure. The technical measures implemented
            differ between infrastructure providers and include Virtual Private
            Cloud (VPC) implementations, security group assignment, and
            traditional firewall rules.
          </li>
          <li>
            Intrusion detection and prevention: Swiftforms implemented a Web
            Application Firewall (WAF) solution to protect hosted customer
            websites and other internet-accessible applications. The WAF is
            designed to identify and prevent attacks against publicly available
            network services.
          </li>
          <li>
            Code review and analysis: Swiftforms employs a static code review
            process to increase the security of the code used to provide the
            Service. This code is reviewed and approved based on peer review
            prior to staging the code.
          </li>
          <li>
            Penetration testing: Swiftforms uses industry recognised penetration
            testing service providers to run fortnightly penetration tests. The
            intent of the penetration tests is to identify and resolve
            foreseeable attack vectors and potential abuse scenarios.
          </li>
        </ul>
        <p>
          <b>
            Access Control:&nbsp; Limitations of Privilege &amp; Authorisation
            Requirements
          </b>
        </p>
        <ul>
          <li>
            Service access: A subset of Swiftforms’ personnel have access to the
            products and to customer data via controlled interfaces. The intent
            of allowing access to these persons is to provide effective customer
            support, to troubleshoot potential problems, to detect and respond
            to security incidents and implement data security. Employees are
            granted access by role, and reviews of high risk privilege grants
            are limited to only the most senior personnel. Personnel roles are
            reviewed at least every six months.
          </li>
          <li>
            Background checks: All Swiftforms employees undergo a background and
            references check prior to being extended a contractual offer, in
            accordance with the applicable laws. All employees are required to
            conduct themselves in a manner consistent with company guidelines,
            non-disclosure requirements, and ethical standards.
          </li>
        </ul>
        <p>
          <b>Transmission Control</b>
        </p>
        <ul>
          <li>
            Data in transit: Swiftforms uses HTTPS encryption (also referred to
            as SSL or TLS) for all communications and transport of data between
            servers and devices, both internally and externally to the Service.
            Swiftforms’ HTTPS implementation uses industry standard algorithms
            and certificates.
          </li>
          <li>
            Data at rest: Swiftforms uses 256 encryption to store user
            passwords, following industry standard practices for security. As of
            25 May 2018, all stored data on the Service is encrypted at rest.
          </li>
        </ul>
        <p>
          <b>Input Control</b>
        </p>
        <ul>
          <li>
            Detection: Swiftforms has designed and configured Service
            infrastructure to log information about the system behaviour,
            traffic received, system authentication, and other application
            requests. Internal systems aggregate log data and alert appropriate
            employees of malicious, unintended, or anomalous activities.
            Swiftforms personnel, including security, operations, and support
            personnel, are responsive to known incidents.
          </li>
          <li>
            Response and tracking: Swiftforms maintains a record of known
            security incidents that includes description, dates and times of
            relevant activities, and incident disposition. Suspected and
            confirmed security incidents are investigated by security,
            operations, or support personnel; and appropriate resolution steps
            are identified and documented. For any confirmed incidents,
            Swiftforms will take appropriate steps to minimise Service and
            Customer damage or unauthorised disclosure.
          </li>
          <li>
            Communication: If Swiftforms becomes aware of unlawful access to
            Customer data stored within the Service, Swiftforms will: 1) notify
            the affected Customers of the incident; 2) provide a description of
            the steps Swiftforms is taking to resolve the incident; and 3)
            provide status updates to the Customer contact, as Swiftforms deems
            necessary. Notification(s) of incidents, if any, will be delivered
            to one or more of the Customer’s contacts in a medium that
            Swiftforms selects, including via email or telephone.
          </li>
        </ul>
        <p>
          <b>Availability Control</b>
        </p>
        <ul>
          <li>
            Infrastructure availability: Swiftforms’ infrastructure providers
            use commercially reasonable efforts to ensure a minimum of 99.9%
            uptime. The providers maintain a minimum of N+1 redundancy to power,
            network, and HVAC services.
          </li>
          <li>
            Fault tolerance: Swiftforms’ backup and replication strategies are
            designed to ensure redundancy and fail-over protections during a
            significant processing failure. Customer data is backed up to
            multiple durable data stores and replicated across multiple data
            centers.
          </li>
          <li>
            Online replicas and backups: Where feasible, production databases
            are designed to replicate data between no less than 1 primary and 1
            secondary database. All databases are backed up and maintained using
            at least industry standard methods.
          </li>
        </ul>
      </div>
    </div>
  );
}
