import AnalyticsFeatures from "../components/AnalyticsFeatures";
import AnalyticsHero from "../components/AnalyticsHero";

export default function Analytics() {
    return (
        <div>
          <AnalyticsHero />
          <AnalyticsFeatures />
        </div>
    )
  }
  