/* This example requires Tailwind CSS v2.0+ */
export default function Hero() {
  return (
    <div className="relative bg-gray-50 overflow-hidden">
      <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 mb-16">
        <div className="text-center">
          <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            <span className="block xl:inline">Cloud form software to</span>{' '}
            <span className="block text-blue-600 xl:inline">digitise your business</span>
          </h1>
          <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Swiftforms allows you to digitise your paperwork, allowing you and your team to spend time doing what you do best.
          </p>
        </div>
      </main>
    </div>
  )
}
