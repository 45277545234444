import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Home from "./pages/home";
import About from "./pages/about";
import TermsAndConditions from "./pages/terms-and-conditions";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivacyPolicy from "./pages/privacy";
import DataProcessing from "./pages/data-processing";
import Gdpr from "./pages/gdpr";
import Features from "./pages/features";
import Pricing from "./pages/pricing";
import Contact from "./pages/contact";
import Analytics from "./pages/analytics";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route path="" element={<Home />} />
        <Route path="features" element={<Features />} />
        <Route path="pricing" element={<Pricing />} />
        <Route path="about" element={<About />} />
        <Route path="terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="privacy" element={<PrivacyPolicy />} />
        <Route path="data-processing-addendum" element={<DataProcessing />} />
        <Route path="gdpr" element={<Gdpr />} />
        <Route path="contact" element={<Contact />} />
        <Route path="analytics" element={<Analytics />} />
        <Route path="*" element={<Home />} />
      </Route>
    </Routes>
  </BrowserRouter>
);
