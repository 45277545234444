import React from "react";
import FeaturesLarge from "../components/FeaturesLarge";
import FeaturesGrid from '../components/FeaturesGrid';
import Connectors from '../components/Connectors';

export default function Contact() {
  return (
    <div>
      <FeaturesLarge />
      <FeaturesGrid />
      <Connectors />
    </div>
  );
}
