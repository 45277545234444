import { CheckIcon } from "@heroicons/react/outline";

const features = [
  {
    name: "Data preparation",
    description: "Clean, transform, enrich, and catalog the data you want to be analysed using our built-in data preparation module",
  },
  {
    name: "Drag & drop reports",
    description:
      "Create data visualisation reports with an easy to use drag & drop interface providing a wide variety of charts, widgets, tables, and table view components",
  },
  {
    name: "Analyse your data",
    description:
      "Use our beautiful interactive reports and dashboards to analyse and gain insights into your data",
  },
  {
    name: "Share your insights",
    description:
      "Share the insights you've gained with our analytics portal, giving you the edge in management meetings with an eagle-eye view of your data",
  }
];

export default function AnalyticsFeatures() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:py-24 lg:px-8">
        <div>
          <h2 className="text-lg font-semibold text-indigo-600">
            Everything you need for data analytics
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900">
            All-in-one platform
          </p>
          <p className="mt-4 text-lg text-gray-500">
            Our data analytics add-on gives you access to a variety of dashboard creation tools that allow you to gain and act on powerful insights into your data.
          </p>
        </div>
        <div className="mt-12 lg:col-span-2 lg:mt-0">
          <dl className="space-y-10 sm:grid sm:grid-flow-col sm:grid-cols-2 sm:grid-rows-2 sm:gap-x-6 sm:gap-y-10 sm:space-y-0 lg:gap-x-8">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <CheckIcon
                    className="absolute h-6 w-6 text-green-500"
                    aria-hidden="true"
                  />
                  <p className="ml-9 text-lg font-medium leading-6 text-gray-900">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
