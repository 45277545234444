import { CheckIcon } from '@heroicons/react/outline'

const features = [
  {
    name: 'Dispatch jobs & tasks',
      },
  {
    name: 'GPS & Map Locations',
      },
  {
    name: 'Capture Photos & More',
      },
  {
    name: 'Push Notifications',
      },
  {
    name: 'Generate PDFs',
      },
  {
    name: 'Capture Signatures',
      },
  {
    name: 'Scan Barcodes & QR Codes',
      },
  {
    name: 'Customisable Look & Feel',
      }
]

export default function FeaturesGrid() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="mx-auto max-w-3xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900">Features at a glance</h2>
          <p className="mt-4 text-lg text-gray-500">
          Our user-friendly tools make it easy to create effective mobile applications that work for your business.
          </p>
        </div>
        <dl className="mt-12 space-y-10 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-4 lg:gap-x-8">
          {features.map((feature) => (
            <div key={feature.name} className="relative">
              <dt>
                <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                <p className="ml-9 text-lg font-medium leading-6 text-gray-900">{feature.name}</p>
              </dt>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}
