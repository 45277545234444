import React from 'react';
import Hero from '../components/Hero';
import Features from '../components/Features';
import SignUp from '../components/SignUp';
import Companies from '../components/Companies';

export default function Home() {
    return (
        <div>
            <Hero />
            <SignUp />
            <Features />
            <Companies />
        </div>
    );
}